























































































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";

@Component({
  components: {}
})
export default class Pagina extends Vue {
  private menu: any = "";
  private menu_cementerio: any = "";
  private menuLateral: any = [];
  private pagina: any = {
    id: "",
    titulo: "",
    nombre_web: "",
    fecha: "",
    banner: "",
    banner_mobile: "",
    contenido: "",
    direccion: "",
    telefono: "",
    email: "",
    horario: "",
    director: "",
    fecha_modificacion: "",
    tipo: "",
    estado: "",
    menu_lateral: ""
  };
  private nombreWeb: any = "";
  private columnasTablaData = [
    {
      name: "nombre",
      label: "Documento",
      align: "left"
    },
    {
      name: "dato",
      label: "Acción",
      align: "left"
    }
  ];
  private dataPagina: any = [];

  get muestraMenu() {
    if (
      this.pagina &&
      this.pagina.menu_lateral == 1 &&
      this.menuLateral.length > 0
    ) {
      return " col-xs-11 col-sm-11 col-md-8 col-lg-8 col-xl-8";
    }
    return " col-xs-11 col-sm-11 col-md-11 col-lg-11 col-xl-11";
  }

  private beforeMount() {
    if (
      this.$router.currentRoute.params.nombre &&
      this.$router.currentRoute.params.nombre != ""
    ) {
      this.nombreWeb = this.$router.currentRoute.params.nombre;
      if (this.nombreWeb == "domsistemas") {
        this.toLink("https://imaarica.cl/domsistemas/", 2);
      } else if (this.nombreWeb == "archivohistorico") {
        this.toLink(
          "https://sites.google.com/municipalidadarica.cl/archivo-historico-municipal/p%C3%A1gina-principal",
          2
        );
      } else {
        this.getPagina();
        this.linkCanonical(this.nombreWeb);
      }
    } else {
      this.routerGo("Home");
    }
  }
  private mounted() {}

  private linkCanonical(nombreWeb) {
    var canonicalLink = document.createElement("link");
    // Establece los atributos del enlace

    canonicalLink.rel = "canonical";
    canonicalLink.href = "https://muniarica.cl/" + nombreWeb;
    document.head.appendChild(canonicalLink);
  }
  private getPagina() {
    this.$q.loading.show({
      delay: 100 // ms
    });
    this.$axios
      .get("pagina?nombre_web=" + this.nombreWeb)
      .then((res: any) => {
        let base = process.env.VUE_APP_BASE_URL;
        this.pagina = res.data;
        if (this.pagina.banner) {
          this.pagina.banner = base + this.pagina.banner;
        } else {
          this.pagina.banner = "#";
        }
        if (this.pagina.banner_mobile) {
          this.pagina.banner_mobile = base + this.pagina.banner_mobile;
        } else {
          this.pagina.banner_mobile = "#";
        }
        document.title = this.pagina.titulo + " - Municipalidad de Arica";

        //MetaData generica
        document.title = this.pagina.titulo + " - Municipalidad de Arica";
        (document.querySelector(
          'meta[name="description"]'
        ) as any).setAttribute("content", "Municipalidad de Arica");
        (document.querySelector('meta[name="image"]') as any).setAttribute(
          "content",
          this.pagina.banner_mobile
        );

        //MetaData Twitter
        (document.querySelector(
          'meta[name="twitter:title"]'
        ) as any).setAttribute(
          "content",
          this.pagina.titulo + " - Municipalidad de Arica"
        );
        (document.querySelector(
          'meta[name="twitter:description"]'
        ) as any).setAttribute("content", "Municipalidad de Arica");
        (document.querySelector(
          'meta[name="twitter:image:src"]'
        ) as any).setAttribute("content", this.pagina.banner_mobile);

        //MetaData Facebook
        (document.querySelector(
          'meta[property="og:title"]'
        ) as any).setAttribute(
          "content",
          this.pagina.titulo + " - Municipalidad de Arica"
        );
        (document.querySelector(
          'meta[property="og:description"]'
        ) as any).setAttribute("content", "Municipalidad de Arica");
        (document.querySelector(
          'meta[property="og:image"]'
        ) as any).setAttribute("content", this.pagina.banner_mobile);

        if (this.pagina.director && this.pagina.director != "") {
          this.dataPagina.push({
            nombre: "Director/a",
            dato: this.pagina.director
          });
        }
        if (this.pagina.direccion && this.pagina.direccion != "") {
          this.dataPagina.push({
            nombre: "Dirección",
            dato: this.pagina.direccion
          });
        }
        if (this.pagina.email && this.pagina.email != "") {
          this.dataPagina.push({
            nombre: "Correo",
            dato: this.pagina.email
          });
        }
        if (this.pagina.telefono && this.pagina.telefono != "") {
          this.dataPagina.push({
            nombre: "Teléfono",
            dato: this.pagina.telefono
          });
        }
        if (this.pagina.horario && this.pagina.horario != "") {
          this.dataPagina.push({
            nombre: "Horario",
            dato: this.pagina.horario
          });
        }
        this.setMenu();
        this.$q.loading.hide();
      })
      .catch((err: any) => {
        this.$q.loading.hide();
        console.log(err);
        this.routerGo("Home");
      });
  }

  private routerGo(name: string) {
    if (name && name != "") {
      if (this.$router.currentRoute.name == name) {
        this.$router.go(0);
      } else {
        this.$router.push({ name: name }).catch(err => {
          console.log(err);
        });
      }
    }
  }

  private async setMenu() {
    const runtimeConfig1 = await fetch("/menu.json");
    this.menu = await runtimeConfig1.json();
    if (this.pagina.menu_lateral == 1) {
      for (let i = 0; i < this.menu.length; i++) {
        this.getSubMenu(this.menu[i], this.pagina.nombre_web, 1);
      }
    }
  }

  private getSubMenu(element: any, nombre_web: string, profundidad) {
    if (element.nombre_web == nombre_web) {
      return true;
    } else {
      if (element.sub_menu && element.sub_menu.length > 0) {
        for (let j = 0; j < element.sub_menu.length; j++) {
          if (
            this.getSubMenu(element.sub_menu[j], nombre_web, profundidad + 1)
          ) {
            if (profundidad == 2) {
              return true;
            } else {
              this.menuLateral = element.sub_menu;
              this.checkSubMenus();
              return false;
            }
          }
        }
      }
      return false;
    }
  }

  private selectedStyle(item: any) {
    if (this.$router.currentRoute.params.nombre == item.nombre_web) {
      item.expand = true;
      return " background-color: #ff9e16 !important; color: #ffffff !important;";
    } else {
      let childSelected = false;
      if (item.sub_menu && item.sub_menu.length > 0) {
        for (let i = 0; i < item.sub_menu.length; i++) {
          if (
            this.$router.currentRoute.params.nombre ==
            item.sub_menu[i].nombre_web
          ) {
            childSelected = true;
          }
        }
        if (childSelected) {
          item.expand = true;
          return " background-color: ff9e16!important; color: #ffffff !important;";
        }
      }
      return "";
    }
  }

  private checkSubMenus() {
    for (let i = 0; i < this.menuLateral.length; i++) {
      if (this.menuLateral[i].sub_menu.length > 0) {
        if (
          this.menuLateral[i].tipo == 2 &&
          this.$router.currentRoute.params.nombre ==
            this.menuLateral[i].nombre_web
        ) {
          this.menuLateral[i].expand = true;
        }
      } else {
        this.menuLateral[i].expand = false;
      }
    }
  }

  private toLink(item: any, blank: any) {
    if (item && item != "") {
      if (blank == 1) {
        window.open(item, "_blank");
      } else {
        window.open(item, "_self");
      }
    } else {
      return;
    }
  }

  private irPagina(nombreWeb: any) {
    if (nombreWeb == "contacto") {
      this.routerGo("Contacto");
    } else if (
      nombreWeb == "actas-del-concejo" ||
      nombreWeb == "actas-del-concejo-municipal"
    ) {
      this.routerGo("ActasConcejo");
    } else if (nombreWeb == "domsistemas") {
      window.open("https://imaarica.cl/domsistemas/");
    } else if (nombreWeb == "archivohistorico") {
      window.open(
        "https://sites.google.com/municipalidadarica.cl/archivo-historico-municipal/p%C3%A1gina-principal"
      );
    } else if (nombreWeb == "concejo") {
      this.routerGo("Concejo");
    } else if (nombreWeb == "telefonos-oficinas" || nombreWeb == "contactos") {
      this.routerGo("Telefonos");
    } else if (nombreWeb == "cosoc") {
      this.routerGo("Cosoc");
    } else if (nombreWeb == "informes-financieros") {
      this.routerGo("Informes");
    } else if (nombreWeb == "ofertas-laborales") {
      this.routerGo("OfertasOmil");
    } else {
      if (nombreWeb && nombreWeb != "") {
        if (
          this.$router.currentRoute.name == "Pagina" &&
          this.$router.currentRoute.params.nombre &&
          this.$router.currentRoute.params.nombre == nombreWeb
        ) {
          this.$router.go(0);
        } else {
          if (this.$router.currentRoute.name == "Pagina") {
            if (
              this.$router.currentRoute.params.nombre &&
              this.$router.currentRoute.params.nombre != nombreWeb
            ) {
              this.$router
                .push({ name: "Pagina", params: { nombre: nombreWeb } })
                .catch(err => {
                  console.log(err);
                });
              this.$router.go(0);
            } else {
              this.$router
                .push({ name: "Pagina", params: { nombre: nombreWeb } })
                .catch(err => {
                  console.log(err);
                });
            }
          } else {
            this.$router
              .push({ name: "Pagina", params: { nombre: nombreWeb } })
              .catch(err => {
                console.log(err);
              });
          }
        }
      }
    }
  }
}
